import "core-js/stable";
import "regenerator-runtime/runtime";
import smoothscroll from "smoothscroll-polyfill";
import userAgentCheck from "./modules/_userAgentChecker";
import { goToPageTop, smoothScroll } from "./modules/_scrolls";
import mq from "./modules/_checkDevice";
import sal from "sal.js";
import $ from "jquery";
import Swiper from "swiper";
import Vivus from "vivus";
import { showAccordion, showAllAccordion }  from "./modules/_showAccodion";

// polyfill
smoothscroll.polyfill();

if (typeof IntersectionObserver === "undefined") {
  // eslint-disable-next-line global-require
  require("intersection-observer");
}

// UA Check Module
userAgentCheck();

// Menu UI Functions
const menuToggleFunc = () => {
  const menuOpen = document.getElementById("menuOpen");
  const menuOpenCts = document.getElementById("menuContent");
  const menuOpenBtn = document.getElementById("menubtn");
  const menuLinks = document.querySelectorAll(".menu-link");
  const menuOpenOn = () => {
    menuOpenCts.classList.add("open");
  };
  const menuOpenOff = () => {
    menuOpenCts.classList.remove("open");
  };
  const menuOpenToggle = () => {
    if (menuOpenCts.classList.contains("open")) {
      menuOpenCts.classList.remove("open");
      menuOpenBtn.classList.remove("active");
    } else {
      menuOpenCts.classList.add("open");
      menuOpenBtn.classList.add("active");
    }
  };


  if (mq("sp")) {

    const topPage = document.getElementById("topPage");
    if (topPage != null) {
      // menuLinkBtnFuncFromSub();
      console.log("SP");
    }

  } else {

    // menuLinkBtnFunc();

    // menuOpen.addEventListener("mouseover", menuOpenOn);
    // menuOpenCts.addEventListener("mouseover", menuOpenOn);
    // menuOpen.addEventListener("mouseleave", menuOpenOff);
    // menuOpenCts.addEventListener("mouseleave", menuOpenOff);
    // console.log(document.body.classList.contains("top-page"));
    // const topPage = document.getElementById("topPage");
    // if (topPage != null) {
    // }
  }

  menuOpenBtn.addEventListener("click", menuOpenToggle);

};

const firstLogoAnimation = () =>{
  new Vivus('firstLogoSvg', { duration: 100 , start: 'autostart', pathTimingFunction: Vivus.EASE_OUT,}, function(obj){
    obj.el.classList.add('done');
    document.querySelector('.top-video').classList.add("show");
    document.querySelector('.top-video').play();
  });

  new Vivus('firstLogoSvg', { //ここにsvgタグにつけたIDを書き込む
    type: 'oneByOne',
    start: 'inViewport',
    duration: 1000,
    forceRender: false,
    animTimingFunction: Vivus.LINEAR,
  });

}

// Gallery Func

const galleryShow = () =>{
  console.log("GALLERY");
//   lightGallery(document.getElementById('aniimated-thumbnials'), {
//     thumbnail:true
// });
};

const topMovie = () => {
  $("#closeBtn").click(function() {
    $(".youtube-wrap00").removeClass("show");
  });
  $("#youtubeBtn").click(function() {
    $(".youtube-wrap00").addClass("show");
    // console.log("hoge");
    return false;
  });

  $("#closeBtn01").click(function() {
    $(".youtube-wrap01").removeClass("show");
  });
  $("#youtubeBtn01").click(function() {
    $(".youtube-wrap01").addClass("show");
    // console.log("hoge");
    return false;
  });

}

const recruitFormScroll = () =>{
    const speed = 500;
    const destinationTop = document.getElementById("recContactArea").offsetTop;
    // URLを取得
    var url = new URL(window.location.href);

    // URLSearchParamsオブジェクトを取得
    var params = url.searchParams;
    if (params.has('status') )
    {
        $("html, body").animate({ scrollTop: destinationTop }, speed, "swing");

    }



}
// All Functions Run
window.addEventListener("DOMContentLoaded", () => {
  sal();
  goToPageTop(".js-page-top");
  smoothScroll(".js-scroll");
  menuToggleFunc();
  showAllAccordion(".js-acordion");
  if(document.querySelector('.home') != null){
    // topSlideShow();
    firstLogoAnimation();
    topMovie();
  }
  if( document.getElementById("galleryPage") != null) {
    galleryShow();
  }

  if( document.getElementById("recContactArea") != null) {
    recruitFormScroll();
  }


});

// ------------------------------------------------------
// スクロール
// ------------------------------------------------------

// ページトップへスクロール
export const goToPageTop = (val) => {
  $(val).on("click", () => {
    $("html, body").animate({ scrollTop: 0 }, 500, "swing");
    return false;
  });
};

// スムーススクロール
export const smoothScroll = (val) => {
  $(val).on("click", (e) => {
    const speed = 500;
    const href = e.currentTarget.href;
    console.log(href);

    const AnckerLink = href.split("#");
    console.log(AnckerLink);
    const destinationTop =
      document.getElementById(AnckerLink[1]).offsetTop - 60;
    $("html, body").animate({ scrollTop: destinationTop }, speed, "swing");
    return false;
  });
};
